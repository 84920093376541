<!--
* @Description: 人脸识别
* @Author: sunchunchun
* @CreateDate: 2024/07/17 15:43:16
* @FileName: Face.vue
!-->
<template>
    <div class="face-box">
        <div class="cardForm">
            <div style="display:flex">
                    <img style="width:29px;height:29px;margin-left:15px;margin-top:12px" src="../../../../../assets/images/shiming-icon.png">
                    <span style="font-size:16px;margin-left:9px;margin-top:19px">实名认证</span>
                </div>
            <div class="img-box">
                <img src="@/assets/images/face-info.png" alt=""
                style="width:162px;height:154px;margin-top:30px">
            </div>
            <div class="text" style="margin-top:14px">
                <div class="title">注意事项</div>
                <div class="text-cont">1. 请您本人进行人脸认证</div>
                <div class="text-cont">2. 认证时需面部正对手机</div>
                <div class="text-cont">3. 保持走位光线充足，录制3~5秒视频</div>
                <div class="btn">
                    <van-button v-if="msg != ''" @click="onFace" class="btns btn-stl-1" round type="info">人脸认证</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FaceVodio",
    props: {
        toName: {
            type: String,
        },
        userId: {
            type: String,
        },
        phone: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            fileList: [],
            msg: '',
        }
    },
    created() {
        this.msg = this.getQueryString('msg')
        if (this.msg=='') {
            this.$toast('识别通过')
            setTimeout(_ => {
                this.goPathIndex()
            }, 800)
        } else {
            if (this.msg) {
                this.$toast(this.msg)
            }
            return false
        }
    },
    methods: {
        // 接收地址栏内的参数
        getQueryString(name) {
            let str = window.location.href.split('?')[1]
            if (str) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
                var r = str.match(reg)
                if (r != null) {
                    return decodeURI(r[2])
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        onFace() {
            let path = this.$route.path
            window.wx.miniProgram.navigateTo({
                url: '/pages/phoneEdit/phoneEdit?data=' + path + '&userId=' + this.userId
            })
        },
        // 审核通过后返回认证页面
        goPathIndex() {
            if (this.toName == 'editPhoneLogin') {
                this.$router.push({
                    name: this.toName,
                    query: {
                        phone: this.phone
                    }
                })
            } else if (this.toName == 'comPhoneListLogin') {
                this.$router.push({
                    name: this.toName,
                    params: {
                        comLice: 1,
                        comFace: 2,
                    },
                    query: {
                        phone: this.phone
                    }
                })
            }
        },
        // 人脸认证 上传前
        beforeRead(file) {
            this.fileList = []
            let isLt5M = file.size / 1024 / 1024 < 50
            if (isLt5M) {
                return true
            } else {
                this.$toast("上传文件不得超过50M")
                return false
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.face-box{
    min-height: 100vh;
    padding: 40px 30px;
    .img-box{
        width: 100%;
        text-align: center;
        img{
            width: 240px;
            height: 240px;
        }
    }
    .text{
        margin-top: 140px;
        padding: 0 45px;
        .title{
            color: #898989;
            font-size: 30px;
            margin-bottom: 10px;
        }
        .text-cont{
            font-size: 28px;
            color: #898989;
            margin-top: 10px;
        }
        .btn{
            width: 100%;
            margin-top: 80px;
            text-align: center;
            .btns{
                padding: 0 170px;
            }
        }
    }
}
/deep/
.van-uploader__preview{
    display: none;
}
</style>