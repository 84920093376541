<!--
* @Description: 
* @Author: sunchunchun
* @CreateDate: 2024/07/17 15:40:16
* @FileName: preFace.vue
!-->
<template>
    <div>
        <FaceVodio :toName="path" :userId="userId" :phone="phone"></FaceVodio>
    </div>
</template>

<script>
import FaceVodio from "./components/Face.vue"
import storage from 'store'
export default {
    components: {
        FaceVodio
    },
    data () {
        return {
            userId: '',
            phone: '',
            path: '',
        }
    },
    created() {
        this.phone = storage.get('phoneNoLoginPhone')
        this.userId = storage.get('phoneNoLoginUserId')
        // 判断是个人  1
        if (storage.get('phoneNoLogins') == 1) {
            this.path = 'editPhoneLogin'
        }
        // 判断是企业  2
        if (storage.get('phoneNoLogins') == 2) {
            this.path = 'comPhoneListLogin'
        }
    }
}
</script>

<style lang='scss' scoped>

</style>